var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"flipbook"},on:{"touchmove":function($event){$event.preventDefault();}}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ssa",attrs:{"src":require("../../assets/images/1-large.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ssa",attrs:{"src":require("../../assets/images/2-large.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ssa",attrs:{"src":require("../../assets/images/3-large.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ssa",attrs:{"src":require("../../assets/images/4-large.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ssa",attrs:{"src":require("../../assets/images/5-large.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"ssa",attrs:{"src":require("../../assets/images/6-large.jpg")}})])
}]

export { render, staticRenderFns }