<template>
	  <div id="flipbook" @touchmove.prevent>
	     <!-- <div class="hard" style="background: aqua;"></div> -->
	     <!-- <div class="hard"> <img src="../../assets/images/7-large.jpg" class="ssa" /></div> -->
	     <div ><img src="../../assets/images/1-large.jpg" class="ssa" /></div>
	     <div ><img src="../../assets/images/2-large.jpg" class="ssa" /></div>
	     <div ><img src="../../assets/images/3-large.jpg" class="ssa" /></div>
	     <div><img src="../../assets/images/4-large.jpg" class="ssa" /></div>
	     <div><img src="../../assets/images/5-large.jpg" class="ssa" /></div>
	     <div><img src="../../assets/images/6-large.jpg" class="ssa" /></div>
	     <!-- <div style="background: aqua;" class="hard"> </div> -->
	     <!-- <div style="background: teal;" class="hard"> </div> -->
	   </div>
</template>

<script>
	import $ from "../../util/jquery-vendor.js"
	import turn from "../../util/turn.js";
	export default {
		data() {
		  return {
			
		  };
		},
		mounted(){
			
			var w = $(window).width();
			var h = $(window).height()-50;
			console.log(w,h)
			$("#flipbook").turn({
			  width: w,
			  height: h,
			  acceleration: true, // 是否加速，对于触摸屏的设备，这个值必须为true
			  autoCenter: true, // 是否居中 默认值false
			   direction: "ltr", // 翻书方向，值为rtl / ltr（3种写法），②HTML: <div id="flipbook" dir="rtl"></div> ③ CSS:#flipbook { direction:rtl; }
			  display: 'single', // 显示单页or双页,默认值是double (如果single, class为hard的div首末各一个就可以)
			  duration:500,
			  gradients:true, //翻译产生阴影和渐变
			  // page:1, //初始化 默认显示的页数
			});
		},
		methods: {
			// stop(){
			// 	                document.body.style.overflow='hidden';//禁止页面划动
			// 	            },
			// 	     move(){
			// 	          document.body.style.overflow='';//出现滚动条
			// 	      },
		},
		  components: {}
	}
</script>

<style lang="scss" scoped>
	#flipbook{
	}
	#flipbook div {
	    text-align: center;
	    // line-height: 500px;
	}
	// .backward,
	// .forward{
	//     width: 40px;
	//     height: 40px;
		
	// }
	.ssa{
		width: 100%;
		height: 100%;
	}
</style>
